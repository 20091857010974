.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
}

.logo {
  font-size: 24px;
  color: #333;
}

.search-bar {
  flex-grow: 1;
  margin: 0 20px;
  padding: 5px;
  font-size: 16px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  font-size: 18px;
}

  
