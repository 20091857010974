.content-section {
  margin: 2rem 0;
}

.content-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.content-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.content-items .manga-item {
  flex: 1 1 calc(33.333% - 1rem);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.content-items .manga-item img {
  width: 100%;
  height: auto;
}

.content-items .manga-item h3 {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
