.main-visual {
  width: 100%;
  height: 400px;
  margin: 2rem 0;
}

.main-visual img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
